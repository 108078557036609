<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">省优秀毕业生名单</div>
    <!--=====================================工具栏========================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="deletemd">删除</el-button>
      <el-button type="primary" size="mini" @click="dialogImportVisible = true"
        >导入</el-button
      >
      <el-button type="primary" size="mini" @click="getDataPageList"
        >查询</el-button
      >
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">届次</div>
          <el-select
            v-model="nj"
            size="mini"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">学院</div>
          <el-select
            v-model="bmbh"
            size="mini"
            clearable
            @change="bmchange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业</div>
          <el-select
            v-model="zybh"
            size="mini"
            clearable
            @change="zychange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级</div>
          <el-select
            v-model="bjbh"
            size="small"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">学号</div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">姓名</div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">状态</div>
          <el-select
            v-model="zt"
            size="small"
            clearable
            style="display: block; width: 178px"
          >
            <el-option label="已申请" :value="1"></el-option>
            <el-option label="未申请" :value="2"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="xh" label="学号"> </el-table-column>
        <el-table-column prop="xm" label="姓名"> </el-table-column>
        <el-table-column prop="xbm" label="性别">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bmmc" label="学院"> </el-table-column>
        <el-table-column prop="zymc" label="专业"> </el-table-column>
        <el-table-column prop="bjmc" label="班级"> </el-table-column>
        <el-table-column prop="nj" label="届次"> </el-table-column>
        <el-table-column prop="sqid" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.xqid == null" type="danger" effect="dark">
              未申请
            </el-tag>
            <el-tag v-else type="success" effect="dark"> 已申请 </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="省优秀学生名单"
      :visible.sync="dialogImportVisible"
      top="5vh"
    >
      <div style="text-align: center">
        <el-upload
          class="upload-demo"
          drag
          :headers="headers"
          :action="uploadurl"
          :multiple="false"
          :on-success="handleSuccess"
          :show-file-list="false"
        >
          <el-icon class="el-icon-upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">请上传excel文件</div>
          </template>
        </el-upload>
        <div>
          <el-link
            type="primary"
            :href="downbaseurl + 'UploadFile/xyxxsmd.xlsx'"
            >模板下载</el-link
          >
          <br />
          <el-link type="danger" v-if="downerrurl" :href="downerrurl"
            >下载错误信息</el-link
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getPYXXSMDPageList, deletePMD } from '../../api/py'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getNFList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      njlist: '',
      nj: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      xm: '',
      spzt: '',
      xh: '',
      xn: '',
      zt: '',
      xnList: [],
      ids: [],
      dialogImportVisible: false,
      uploadurl: baseUrl + 'py/SY_PY_PYXXS_XSSQ/upload',
      downbaseurl: baseUrl,
      headers: '',
      downerrurl: '',
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getNFList().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data
      }
    })
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    reload() {
      this.$router.go(0)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getPYXXSMDPageList({
        queryJson: JSON.stringify({
          XM: this.xm,
          XH: this.xh,
          NJ: this.nj,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          ZT: this.zt,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    handleSelectionChange(val) {
      let i = 0
      this.ids = []
      for (i = 0; i < val.length; i++) {
        this.ids.push(val[i].id)
      }
    },
    // 审批通过
    deletemd() {
      if (this.ids.length === 0) {
        this.$message.error('请选择需要删除的数据！')
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.tableloading = true
            deletePMD({ ids: this.ids }).then((res) => {
              this.tableloading = false
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {
            this.tableloading = false
          })
      }
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.dialogImportVisible = false
        this.getDataPageList()
      } else {
        this.$message.error(response.message)
        this.downerrurl = this.downbaseurl + response.data.url
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
  text-align: right;
  margin-right: 5px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
